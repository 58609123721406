import React from 'react';
import { withRouter } from 'react-router-dom';
import "./mixpanel";
import { Mixpanel } from './mixpanel';

class MixPanelScreenChange extends React.Component {
    componentDidUpdate({ location, history }) {

        if (location.pathname === this.props.location.pathname) {
            // don't log identical link clicks (nav links likely)
            return;
        }

        if (history.action === 'PUSH') {
            Mixpanel.track('screen' + location.pathname); //document.title 
        }
    }

    render() {
        return null;
    }
}

export default withRouter(MixPanelScreenChange);