import React, { useState, useEffect } from "react";
import "./index.css";
import Login from "./Login";

import { Route, BrowserRouter as Router, withRouter } from "react-router-dom";
import HttpsRedirect from "react-https-redirect";
import MixPanelScreenChange from "./routelistener.js";

import { userAtom } from "./atom.js";
import { useRecoilState } from "recoil";


require("dotenv").config();
if (process.env.NODE_ENV !== "development") {
  if (window.location.hostname==='oauth-sandbox.untied.io') {
    global.api_url = "api-sandbox.untied.io";
  } else {
    global.api_url = "api.untied.io";
  }
} else {
  global.api_url = process.env.REACT_APP_API_URL;
}
global.partner_id = "775dbaef-6ac1-49a1-bfea-9ce82b8dbd2a";
global.admin_ips = ["3.8.213.59", "89.243.255.61", "35.246.107.246"];

//heartbeat intervals 

const Routes = (props) => {

  return (
    <HttpsRedirect>
        <Router>
          <div>
            <Route path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Login} />
            <Route path="/logout/:logout" component={Login} />
            <Route path="/magiclink/:token/:email/:jwt" component={Login} />
          </div>
          <MixPanelScreenChange />
        </Router>
     </HttpsRedirect>
  );
};

export default withRouter(Routes);
