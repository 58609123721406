import React, { useState, useRef, useEffect } from "react";
import { userAtom, userCheckoutAtom, taxYearsAtom, profileAtom } from './atom.js'
import { useRecoilState } from 'recoil'
import "./App.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Mixpanel } from "./mixpanel";
import { isMobile } from 'react-device-detect';
import moment from "moment";
import {
  IonAlert, IonGrid, IonRow, IonCol
} from '@ionic/react';
import { getTimestamp } from './components/timeStamp.js'

import { useToasts } from 'react-toast-notifications'

import { styles } from './LoginStyles'

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}

const Login = (props) => {
  // recoil state
  const [user, setUser] = useRecoilState(userAtom)
  const [tax_years_atom, setTaxYearsAtom] = useRecoilState(taxYearsAtom)
  const [profile_atom, setProfileAtom] = useRecoilState(profileAtom)
  // end of recoil state

  const firstTimeRender = useRef(true)
  const [users, setUsers] = useState([])
  const [userId, setUserId] = useState()
  const [agent, setAgent]= useState()
  const [userCheckout, setUserCheckout] = useState(userCheckoutAtom)
  const [pageLoaded, setPageLoaded] = useState(false)
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [usePassword, setUsePassword] = useState(false)
  const [doingMagiclink, setDoingMagiclink] = useState(false)
  const [secretcode, setSecretcode] = useState("")
  const [loggedin, setLoggedin] = useState(false)
  const [goBack, setGoBack] = useState(false)
  const [headingText, setHeadingText] = useState("The UK's personal tax app")
  const [placeholder, setPlaceholder] = useState("Have a secret code? Paste it here.")
  const [secureEntry, setSecureEntry] = useState(true)
  const [ignoreDeviceCheck, setIgnoreDeviceCheck] = useState(false)
  const [target, setTarget] = useState('')
  const [clientToken, setClientToken] = useState("")
  const [agentCode, setAgentCode] = useState(null)
  const [showCheckout, setShowCheckout] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [oAuthError, setOAuthError] = useState(false)
  //const [usingMagicLink, setUsingMagicLink] = useState(false)
  const [error, setError] = useState({
    errorTitle: "",
    errorSubTitle: "",
    errorText: ""
  })
  const [showExpLinkError, setShowExpLinkError] = useState(false)

  useEffect(()=>{
    if(userId && users.length>0 && agent){
      // connectUser()
    }
  },
  [userId, users, agent])

  useEffect(() => {
    if (!pageLoaded) {

      // console.log('in login')

      // let old_token = localStorage.getItem('token');
      // if (old_token) {
      //   console.log('have old token - invalidate it')
      //   fetch(`https://${global.api_url}/invalidateToken.php?ts=${getTimestamp()}`, {
      //     method: "POST",
      //     headers: { token: old_token }
      //   })
      // }

      firstTimeRender.current = false
      if(props.location && props.location.search){
        let qs = new URLSearchParams(window.location.search)
        let data = qs.get("client")
        if (data !== "" && data !== null){
          // data = JSON.parse(data)
          // AUTH IN HERE MAYBE
          // agentPasswordAuth(data.email, data.password, data.userId)
          validateClient(data)
        }

        let email = qs.get("email")
        if (email !== "" && email !== null) {
          setUsername(email)
        }
        
      } else if (props.match != null) {
       
        if (props.match.params.token) {
          let token = props.match.params.token;
          let email = props.match.params.email;
          let jwt = props.match.params.jwt
          setClientToken(jwt)
          recoilAuth(email, token, false, '', true, jwt)
          setUsername(email)          
        }

        //clear local storage apart from agent_code ...
        // const agent_code = localStorage.getItem('agent_code');
        // localStorage.clear()        
        // localStorage.setItem('agent_code', agent_code)
      
      } else {
        localStorage.clear()
      }

      // let urlParams = new URLSearchParams(window.location.search);
      // const url_target = urlParams.get('target')
      // if (url_target != null) {
      //   localStorage.setItem("target", target);
      //   setTarget(url_target)
      //   setIgnoreDeviceCheck(true)
      // }
      // const passed_email = urlParams.get('email');
      // if (passed_email != null) {
      //   setUsername(passed_email)
      // }

      // const agent_code = urlParams.get('agentCode');
      // if (agent_code != null) {
      //   localStorage.setItem('agent_code', agent_code)
      //   setAgentCode(agent_code);
      // }

      setPageLoaded(true)
    }
  }, [])

  const validateClient = (data) => {
    console.log(data)
    console.log('validating token');
      fetch( `https://${global.api_url}/oauth/validateToken.php`,
            {
              method: "GET",
              headers: { clientToken: data }
            }
          )
            .then(res => res.json())
            .then(json => {
              console.log(json)
              if(json.status == "success"){
                 setClientToken(json.client_token)
              }
              if(json.status == "error")
              {
                console.log(json)
                setOAuthError(true)
              }
              //all things going well this should return the new token
             // and hopefully give us a redirect url

            });
    }



  
  // Step 3 - untied oAuth app
  // the untied auth app extracts the token from the client query string parameter and validates it,
  //  getting a new token in exchange:
  //  /oauth/validateToken.phpResponse 
  //  example:{    
  //    "status": "success",    
  //    "client_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJjbGllbnRfaWQiOiJtYXJjdXNfdGVzdCIsImlzcyI6InVudGllZC5pbyIsImV4cCI6MTYzNDY2MTI3MH0.rrzDa6zEAXXFcopMqFbmTrUgSl-Xl6qvGfd1izrOe5sSw7onywRO07DVSefhTQhgKpPPI3QKJWO-8QcBpIzkCTpyPPfqxKvat2zt-Ib3gzWAjhg1-nywvVw7LRLotrwQR-qvLhk0vApmLIf7u_m9DilsHwc_KnvPbgXgI1oNuXL_zX9NlNT9c1NEedboJ5B1PWmMkVV00v2r9cJwq_kSamEuqG8gcrxy9RSyLJr62_XZ2XEqZHiecWxA7Olzhy-v4iqn--u68lqEQgUM6OR5Dk4ZtzaxB4YJvjoyeo6q7Mq5F24P0HxaySTJRtfNmHflibt8Ew8SzPoN4X_4I3u4VSnUfjLmoz5xsODKc3hBfKJFr4R1BSbfXdD_r5YfOavKvVQP9IlHlZOGWMXRlKRUHV2m81N-9Hin6OYqN_Gw5hnGoAUV2fkwciTJYSKyYNy0l-l44bZf8m235RFBG73HYVW-5pmb1Gp5gGFJyoHE9RiL-X5PA8FEsQoAVNn8fmbwLuA9VNmNU1nK5wpGYz3u-GN8GzyjR8qxI1T3GHNCLL0ZAvAgGAl5IPwSf_BmpQDgAqL07g7Tjtj0DeKzJBmuwvvRKmZe-7i_RZFF1e2ruiflb3lMWZwFySa-dsyBwfImmwyMaJsLBpYfvuPhS1ZJZAr3PVj0kFV0F3OWt9WHb3w"}
  //    Tokens   can   only   be   used   once.
  //   validate Token returns a new token and invalidates the old one.




  const yyyymmdd = () => {
    function twoDigit(n) {
      return (n < 10 ? "0" : "") + n;
    }
    var now = new Date();
    return (
      "" +
      now.getFullYear() +
      "-" +
      twoDigit(now.getMonth() + 1) +
      "-" +
      twoDigit(now.getDate())
    );
  }

  const doPurchase = (iap_item) => {
    const body = JSON.stringify({
      email: userCheckout.checkoutEmail,
      guid: userCheckout.checkoutGuid,
      start_date: yyyymmdd(),
      plan: "2",
      sub_sku: iap_item === 627400 ? 'untiedy1' : 'untiedm1',
      store: "untied",
      app: 'web'
    });


    let timeStamp = Math.floor(Date.now() / 1000);
    fetch(
      `https://${global.api_url}/addUserSubscription.php?ts=${timeStamp}&duration=year`,
      {
        method: "POST",
        body: body,
        headers: { partnerId: global.partner_id }
      }
    )
      .then(res => res.json())
      .then(json => {
        //all good, now we can send user back to login page ....
        alert('Thanks for subscribing. You can now log in.')
        window.location.reload(false);
      });

  }


  const openCheckout = (sku) => {
    Mixpanel.track("opened_checkout", { sku: sku });
    const guid = userCheckout.checkoutGuid
    const email = userCheckout.checkoutEmail
    window.Paddle.Checkout.open({
      product: sku, passthrough: guid, email: email,
      successCallback: () => { doPurchase(sku) },
    });
  }

  // const withToast = (Component) => {
  //   return WrappedComponent(props, Component)
  // }

  const WrappedComponent = (props, Component) => {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }

  const EyeIcon = (props) => {
    if (props.open === true) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" /></svg>
      )
    } else {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none" /><path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" /></svg>
      )
    }
  }

  const isEdgeOrIE = () => {
    const IE = (window.navigator.userAgent.indexOf('MSIE') > 0 || !!window.navigator.userAgent.match(/Trident.*rv:11\./));
    const Edge = (/Edge\/\d./i.test(window.navigator.userAgent))

    return IE || Edge;
  }


  const updateUsername = (event) => {
    setUsername(event.target.value)
  };

  const updatePassword = (event) => {
    setPassword(event.target.value)
  };


  const validateEmail = (email) => {
    // eslint-disable-next-line
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const doSecretCode = () => {
    recoilAuth(username, secretcode);
    props.removeAllToasts()
  }



const getProfile = (token, agentId) => {
    fetch(`https://${global.api_url}/getUserProfile.php?ts=${getTimestamp()}`, {
      method: "GET",
      headers: { token, agentId }
    })
      .then(res => res.json())
      .then(json => {
        json.dob = moment(json.dob, 'DD/MM/YYYY').toISOString()
        localStorage.setItem("support_token_start", json.support_token_start || "");
        setProfileAtom({ ...json })
      })
  }

  // const connectUser = () => {
  //   const user_info = users.find((user) => {
  //     return user.id == userId
  //   })
  //   setUser({
  //     ...user_info,
  //     agent: agent.id
  //   })

  //   localStorage.setItem('agent', agent.id)
  //   localStorage.setItem('agentEmail', agent.email)
  //   localStorage.setItem('guid', user_info.id);
  //   //localStorage.setItem('userid', json.id);
  //   localStorage.setItem("token", user_info.token);
  //   localStorage.setItem("username", user_info.email);
  //   localStorage.setItem("agentEmail", username)
  //   localStorage.setItem("mtd", user_info.mtd);

  //   Mixpanel.identify(user_info.id);
  //   Mixpanel.track("log_in");
  //   Mixpanel.people.set({
  //     $email: user.email,
  //     dev: process.env.NODE_ENV != "production"
  //   });
   
  //   props.removeAllToasts()
  //   setLoggedin(true)

  // }


  const recoilAuth = (email, token, usePassword = false, agent = '', withMagicLink = false, jwt = "") => {

    let body = JSON.stringify({ email: email, token: token, agentCode: "",});
    
    if (usePassword) {
      body = JSON.stringify({ email: email, password: token, agentCode: "",});
    }
    let client_token = jwt !== "" ? jwt : clientToken
    //console.log(jwt, client_token)

    console.log(client_token);

    fetch(`https://${global.api_url}/getToken.php?ts=${getTimestamp()}`, {
      method: "POST",
      body: body,
      headers: { partnerId: global.partner_id, clientToken: client_token }
    })
      .then(res => res.json())
      .then(json => {

        console.log(json)
  
        if (json.status === "success") {
          console.log("SUCCESS", json)
          window.location.href = json.redirectUri
        } else {
          console.log("NO SUCCESS", json)

          //if error renew client token
          if (json.client_token) setClientToken(json.client_token);

          if (json.info &&  (json.info === 'trial_expired' || json.info === 'subscription_invalid') ) {
            // this.setState({ showError: true, errorButtons: 'all', errorTitle: "Trial Expired", errorSubTitle: "", errorText: "Your trial has expired, if you wish to continue you will need to purchase a subscription." })
            setUserCheckout({
              showCheckout: true,
              checkoutGuid: json.guid,
              checkoutEmail: email,
              showMessage: false,
              messageTitle: 'Your trial has expired',
              heading_text: "Your trial has expired",
              messageSubTitle: '',
              messageText: "Sorry, your 30 day trial has expired. Please purchase a plan."
            })

            setShowCheckout(true)
            Mixpanel.track("Expired trial");
            return;
          }

          if (!usePassword) {
            recoilAuth(email, token, true);
            return;
          }

          if (json.info === "too_many_attempts") {
            setError({
              showError: true,
              errorTitle: "Unauthorised",
              errorSubTitle: "",
              errorText: "Too many failed attempts. Please wait 5 minutes and try again.",
              buttons:''
            })
          } else {

            if (withMagicLink) {
              //do this to show the magic link option
              setShowPassword(true)
              setShowExpLinkError(true)


            } else {

              setError({
                showError: true,
                errorTitle: "Unauthorised",
                errorSubTitle: "",
                errorText: usePassword 
                  ? "There was a problem logging you in. Please check you have entered the correct email and password.<br><br>If you don't have a password set up yet, click 'Email a magic link'." 
                  : "There was a problem logging you in, please check you have entered the correct secret code.",
                buttons:''
              })
            }
          }

          Mixpanel.track("Unsuccessful login");

        }
      });

  }

  const handleKeyDownSecret = (e) => {
    if (e.key === 'Enter') {
      doSecretCode();
      // recoilAuth(username, password, true);
      //props.removeAllToasts()
    }
  }

  const handleKeyDownEmail = (e) => {
    if (e.key === 'Enter') {
      // doLogin(true, false);
      setShowPassword(true)
    }
  }

  const handleKeyDownPassword = (e) => {
    if (e.key === 'Enter') {
      recoilAuth(username, password, true);
      props.removeAllToasts()
    }
  }

  const doLogin = (usemagiclink = true, register = true) => {
    if (username === "") {
      if (isEdgeOrIE()) { alert("Please enter your email address"); return };

      setError({
        showError: true,
        errorTitle: "Missing Email",
        errorSubTitle: "",
        errorText: "Please enter your email address.",
        buttons:''
      })
      return;
    }

    if (!validateEmail(username)) {
      console.log(isEdgeOrIE());
      if (isEdgeOrIE()) { alert("Please enter a valid email address"); return };

      setError({
        showError:true,
        errorTitle: "Invalid Email",
        errorSubTitle: "",
        errorText: "Please enter a valid email address.",
        buttons:''
      })
      return;
    }

    if (usemagiclink) {

      Mixpanel.track("pressed_get_started");
      setDoingMagiclink(true);

      const encoded_username = encodeURIComponent(username)

      console.log('magic link')
      console.log(clientToken)

      //useToasts().addToast({ heading_text: register ? "Welcome!\r\nCheck your email to continue" : "Welcome back!\r\nCheck your email to continue" }, { appearance: 'success' });
      setHeadingText(register ? "Welcome!\r\nCheck your email to continue" : "Welcome back!\r\nCheck your email to continue");
      fetch(
        `https://${global.api_url}/userFromEmail.php?email=${encoded_username}&ts=${getTimestamp()}&app=aut`,
        {
          method: "GET",
          headers: { partnerId: global.partner_id, clientToken: clientToken  }
        }
      )
        .then(res => res.json())
        .then(json => {
          if (json.status === "success") {
            Mixpanel.alias(json.guid);

            let toast_message = "We've sent you a special link and a secret code in your email so that you can log in. Click the link in the email to continue, or copy and paste the secret code into this screen.";
            if (!register) {
              toast_message += " If you have a password set up you can use that instead of the secret code."
            }
            props.addToast(toast_message, { appearance: 'success' })

            setClientToken(json.client_token)

          } else {
            setError({
              showError:true,
              errorTitle: "Unauthorised",
              errorSubTitle: "",
              errorText: "There was a problem setting up your account.",
              buttons:''
            })
          }
        });
    } else {
      // want to use password ...
      setUsePassword(true)
      setGoBack(true);
      Mixpanel.track("pressed_use_password");
    }
  }

  const doBottomView = () => {

   if (!ignoreDeviceCheck) {
      if (isMobile) {
        return (
          <div>
            <p style={{ fontSize: 14, paddingTop: 0, color: "white" }}>
              This web app is not optimised for mobile devices. Please download our native Android or iPhone app from the links below.
          </p>

            <p>
              <a href="https://apps.apple.com/gb/app/untied/id1458630284">
                <img
                  className="alignnone wp-image-3516"
                  src="https://www.untied.io/wp-content/uploads/2019/11/apple-app-store-badge.png"
                  alt="Download untied from the Apple App Store"
                  width="176"
                  height="68"
                  srcSet="https://www.untied.io/wp-content/uploads/2019/11/apple-app-store-badge.png 646w, https://www.untied.io/wp-content/uploads/2019/11/apple-app-store-badge-300x116.png 300w"
                  sizes="(max-width: 176px) 100vw, 176px"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.untied.umiles">
                <img
                  className="alignnone wp-image-3515"
                  src="https://www.untied.io/wp-content/uploads/2019/11/play.png"
                  alt="Download untied from Google Play"
                  width="176"
                  height="68"
                  srcSet="https://www.untied.io/wp-content/uploads/2019/11/play.png 646w, https://www.untied.io/wp-content/uploads/2019/11/play-300x116.png 300w"
                  sizes="(max-width: 176px) 100vw, 176px"
                />
              </a>
            </p>

            <a href="#" onClick={() => setIgnoreDeviceCheck(true)}>It's ok, let me in!</a>

            <p style={{ fontSize: 10, paddingTop: 20, color: "white" }}>
              © 2019-2021 untied | All rights reserved |{" "}
              <a
                style={{ color: "#FFF" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.untied.io/untied-privacy-policy/"
              >
                Privacy Policy
            </a>{" "}
            |{" "}
              <a
                style={{ color: "#FFF" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.untied.io/untied-app-terms-conditions/"
              >
                Terms of Service
            </a>
            </p>
          </div>
        )
      }
    }
    if (showCheckout) {
      return (
        <IonGrid style={{ width: '100%', marginTop: 0, paddingTop: 0, textAlign: 'center' }}>
          <IonRow>
            <IonCol><h2 style={{ marginTop: 0, paddingTop: 0, color: "#c7ff00" }}>Purchase a plan</h2></IonCol>
          </IonRow>

          <IonRow >
            <IonCol style={{ display: 'flex', justifyContent: 'center' }}>

              <div
                onClick={() => {
                  openCheckout(627400)
                }}
                style={styles.paddleButton}
              >
                <p style={{ fontSize: 12, backgroundColor: 'yellow', marginLeft: '20%', marginRight: '20%', marginTop: 2 }}>Best value</p>

                <p style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 5 }}>£99.99/year</p>
                <p style={{ fontSize: 12, paddingBottom: 5, paddingTop: 0, marginTop: 0 }}>Yearly</p>
              </div>


            </IonCol>
            <IonCol style={{ display: 'flex', justifyContent: 'center' }}>

              <div
                onClick={() => {
                  openCheckout(620338)
                }}
                //style={{ ...styles.buttonContainer, borderColor: this.state.btn2Selected ? "#ff015a" : 'white' }}
                style={styles.paddleButton}
              >
                <p style={{ fontSize: 11, backgroundColor: 'white', marginLeft: '20%', marginRight: '20%', marginTop: 2 }}>&nbsp;</p>
                <p style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 5 }}>£9.99/month</p>
                <p style={{ fontSize: 12, paddingBottom: 5, paddingTop: 0, marginTop: 0 }}>Monthly</p>
              </div>


            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol><p><a href="http://www.untied.io/#pricing" target="_new">Compare plans</a></p></IonCol>
          </IonRow>


        </IonGrid>
      )
    }


    if (!doingMagiclink && !usePassword) {
      return (
        <div>
          <TextField
            id="inputFocus"
            autoFocus
            style={styles.input}
            onKeyPress={handleKeyDownEmail}
            autoCapitalize="none"
            value={username}
            onChange={event => updateUsername(event)}
            returnkeytype="next"
            placeholder="Email address"
            margin="none"
            InputProps={{ disableUnderline: true }}
          />

          {showPassword && 
            <div style={{paddingTop:5}}>
                    <TextField
                    id="inputFocus"
                    autoFocus
                    style={styles.input}
                    onKeyPress={handleKeyDownPassword}
                    autoCapitalize="none"
                    type="password"
                    value={password}
                    onChange={event => updatePassword(event)}
                    returnkeytype="next"
                    placeholder="Password"
                    margin="none"
                    InputProps={{ disableUnderline: true }}
                  />

                <p style={{ fontSize: 16, paddingTop: 5, color: "white", textDecorationLine:'underline', cursor:'pointer' }}
                onClick={() => {
                  Mixpanel.track("requested_magic_link")
                  console.log("doing magic link route")
                  doLogin(true, false)
                  }
                }
                >
                    No password? Email a magic link.

                </p>

              </div>
          }

          <div
            style={{
              flexDirection: "row",
              flex: 1,
              paddingBottom: 10,
              paddingLeft: 10,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center"
            }}
          />

          <IonGrid style={{padding:0}}>

          <IonRow>

            <IonCol style={{paddingLeft:0}}>

          <Button
            style={styles.buttonContainer}
            size="small"
            onClick={() => {

              if (showPassword) {
                Mixpanel.track("password_login_attempt")
                console.log("doing login with password route")
                recoilAuth(username, password, true);
                props.removeAllToasts()
              } else {
                console.log("showing password")
                setShowPassword(true)
              }
              

              // doLogin(true, false)
            }}
          >
            Login
          </Button>
          </IonCol>

          <IonCol style={{paddingRight:0}}>
          <Button
            style={styles.buttonContainer2}
            size="small"
            onClick={() => {
              doLogin(true, true)
              console.log("doing sign up route")
              }}
          >
            Sign up
          </Button>
          </IonCol>

          </IonRow>
          </IonGrid>

          <p style={{ fontSize: 14, paddingTop: 0, color: "white" }}>
            The untied web app is a companion for the untied mobile app. Use the mobile app to track your mileage and
            keep on top of your taxes while you are on the go.
          </p>

          <p>
            <a href="https://apps.apple.com/gb/app/untied/id1458630284">
              <img
                className="alignnone wp-image-3516"
                src="https://www.untied.io/wp-content/uploads/2019/11/apple-app-store-badge.png"
                alt="Download untied from the Apple App Store"
                width="176"
                height="68"
                srcSet="https://www.untied.io/wp-content/uploads/2019/11/apple-app-store-badge.png 646w, https://www.untied.io/wp-content/uploads/2019/11/apple-app-store-badge-300x116.png 300w"
                sizes="(max-width: 176px) 100vw, 176px"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.untied.umiles">
              <img
                className="alignnone wp-image-3515"
                src="https://www.untied.io/wp-content/uploads/2019/11/play.png"
                alt="Download untied from Google Play"
                width="176"
                height="68"
                srcSet="https://www.untied.io/wp-content/uploads/2019/11/play.png 646w, https://www.untied.io/wp-content/uploads/2019/11/play-300x116.png 300w"
                sizes="(max-width: 176px) 100vw, 176px"
              />
            </a>
          </p>

          <p style={{ fontSize: 10, paddingTop: 20, color: "white" }}>
            © 2019-2021 untied | All rights reserved |{" "}
            <a
              style={{ color: "#FFF" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.untied.io/untied-privacy-policy/"
            >
              Privacy Policy
            </a>{" "}
            |{" "}
            <a
              style={{ color: "#FFF" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.untied.io/untied-app-terms-conditions/"
            >
              Terms of Service
            </a>
          </p>
        </div>
      );
    } else {

      if (usePassword) {
        setPlaceholder("Password");
      }
      return (

        <div>
          <div
            style={{
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
              justifyContent: "center"
            }}
          >

            <div
              style={{
                paddingLeft: 0,
                flex: 1,
                flexItems: 3,
                borderWidth: 0,
                flexDirection: 'row',
                marginBottom: 30,

              }}
            >
              <div style={{
                float: 'left', width: '95%', borderRadius: scale(4),
                borderColor: "#ffffff",
                borderStyle: "solid",
                borderWidth: scale(1),
                backgroundColor: "#84C8D9",
                color: "#ffffff",
                paddingLeft: 16,
                placeholderTextColor: "#ffffff",
                textAlign: "center",
                fontSize: scale(14),
                marginLeft: 0
              }}>
                <TextField
                  autoFocus

                  onKeyPress={handleKeyDownSecret}
                  style={{ width: '90%' }}
                  placeholder={placeholder}
                  type={secureEntry ? 'password' : 'text'}
                  //color="white"
                  value={secretcode}
                  onChange={event =>
                    setSecretcode(event.target.value)
                  }
                  InputProps={{
                    disableUnderline: true,
                    className: styler.multilineColor
                  }}
                />

                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: 0,
                    borderWidth: 2,
                    paddingRight: scale(8),
                    paddingTop: scale(5),
                    float: 'right'
                  }}
                  onClick={() => setSecureEntry(!secureEntry)}>

                  <EyeIcon open={secureEntry} style={{ paddingTop: 5 }} />
                </div>
              </div>
            </div>

            <div style={{ paddingTop: 50 }}>
              <Button
                onClick={() => doSecretCode()}
                style={styles.buttonContainer}
              >
                {" "}
                Login{" "}
              </Button>

                <Button
                  style={styles.buttonContainer2}
                  size="small"
                  onClick={() => {
                    setDoingMagiclink(!doingMagiclink)
                    setHeadingText("The UK's personal tax app")
                  }
                  }
                >
                  Back
                </Button>


              <p style={{ fontSize: 10, paddingTop: 20, color: "white" }}>
                © 2019-2021 untied | All rights reserved |{" "}
                <a
                  style={{ color: "#FFF" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.untied.io/untied-privacy-policy/"
                >
                  Privacy Policy
                </a>{" "}
                |{" "}
                <a
                  style={{ color: "#FFF" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.untied.io/untied-app-terms-conditions/"
                >
                  Terms of Service
                </a>
              </p>
            </div>
          </div>
        </div >
      );
    }
  }

  function scale(val) {
    return val;
  }

  const styler = theme => ({
    multilineColor: {
      color: "red"
    }
  });

    if (loggedin && pageLoaded) {
    // return <Redirect
    //     to={{
    //       pathname: "/" + target
    //     }}
    // />
    console.log("Logged in now, hurrah")
  }

  return (
    <div
      style={{
        backgroundColor: "#FAFAFC",
        width: "100%",
        height: "120vh",
        paddingTop: 30
      }}
    >
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
      >
      {!oAuthError ? 
        <Card
          style={{ backgroundColor: "#0c90b2", width: 400, borderRadius: 5 }}
        >
          <CardContent>
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                flex: 0.6,
                justifyContent: "center",
                textAlign: "center"
              }}
            >
              <img
                style={{
                  width: "100%",
                  paddingLeft: -40,
                  paddingTop: 20
                }}
                alt=""
                src={require("./images/untied-white-logo.png")}
              />
            </div>
            <p
              style={{
                fontSize: 18,
                color: "#ffffff",
                paddingBottom: 30,
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                textTransform: "none",
                fontWeight: 700
              }}
            >
              {headingText}
            </p>
            {doBottomView()}
          </CardContent>
        </Card>
        : <div>Invalid client credentials</div>}
      </div>

      <IonAlert
        isOpen={error.showError}
        onDidDismiss={() => setError({showError:false})}
        header={error.errorTitle}
        subHeader={error.errorSubTitle}
        message={error.errorText}
        buttons={
          error.buttons != 'all'
            ? ['OK']
            : [
              {
                text: 'View Plans',
                role: 'cancel',
                cssClass: 'secondary',
                handler: blah => {
                  window.location.href = "https://www.untied.io/#pricing"
                }
              },
              'OK'
            ]
        }
      />

      <IonAlert
        isOpen={showExpLinkError}
        onDidDismiss={() => setShowExpLinkError({showExpLinkError:false})}
        header={"Unauthorised"}
        subHeader={""}
        message={"It looks like your magic link has expired. Shall we email you a new one?"}
        buttons={
            [         {
              text: 'No',
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => { }
              },
              {
                text: 'Yes please',
                cssClass: 'secondary',
                handler: blah => {
                  doLogin(true, false)
                  setShowExpLinkError(false)
                }
              }
              
            ]
        }
      />

    </div>
  );

}

export default withToast(Login)
